<template>
  <div>
    <div id="gradediv1">
      请对培训讲师打分
    </div>
    <div v-if="!dialogAddGrade">
      <div v-for="(item, i) in questions" class="gradediv2">
        <span>{{ item.question }}</span>
        <span>(0~{{ item.grade }})</span>
        <div style="margin-bottom: 10px"></div>
        <el-rate
            v-if="item.grade === '10'"
            v-model="grade[i]"
            :texts="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
            show-text
            :max="item.grade * 1"
        >
        </el-rate>
        <el-rate
            v-if="item.grade === '20'"
            v-model="grade[i]"
            :texts="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']"
            show-text
            :max="item.grade * 1"
        >
        </el-rate>
      </div>
      <div style="float: right;margin-right: 50px">
        <el-button type="primary" @click="upgrade">提交</el-button>
      </div>
    </div>
    <div>
      <el-dialog v-model="dialogAddGrade" title="请填写基础信息" width="90%" :show-close="false" :close-on-click-modal="false">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="您的岗位">
            <el-input v-model="form.post" clearable></el-input>
          </el-form-item>
          <el-form-item label="今日培训讲师">
            <el-input v-model="form.lecturer" clearable></el-input>
          </el-form-item>
          <el-form-item label="今日培训内容">
            <el-input type="textarea" v-model="form.content" clearable></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="qrjcxx">确认</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "addgrade",
  data() {
    return {
      falg: true,
      dialogAddGrade: true,
      questions: [],
      form: {
        post: '',
        lecturer: '',
        content: ''
      },
      formgrade:{
        que: [],
        grade: []
      },
      grade: []
    }
  },
  methods:{
    qrjcxx(){
      if (this.form.post !== '' && this.form.lecturer !== '' && this.form.content !== ''){
        this.dialogAddGrade = false;
        this.axios.get('/imdgrade/getque', (response) => {
          this.questions = response.obj;
          this.questions.forEach((item) => {
            this.grade.push(0)
          })
        })
      }else {
        ElMessage.error("信息未填写完整")
      }
    },
    upgrade(){
      if (this.falg){
        this.falg = false;
        this.questions.forEach((item) => {
          this.formgrade.que.push(item.id)
        });
        this.grade.forEach((item) => {
          this.formgrade.grade.push(item)
        });
        this.axios.post('/imdgrade/addgrade', (response) => {
          if (response.obj){
            this.$router.push('/ydsuccess');
          }
        }, {
          grademain: JSON.stringify(this.form),
          gradenum: JSON.stringify(this.formgrade)
        })
      }else {
        ElMessage.error("请不要重复提交")
      }

    }
  }
}
</script>

<style scoped>
#gradediv1{
  text-align: center;
  font-size: 23px;
  margin-top: 50px;
  margin-bottom: 30px
}

.gradediv2{
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  font-size: 18px;
}
</style>